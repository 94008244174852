import { IState } from "../types";

// 未登录的用户信息
const noUser = {
  userID: '',
  userName: ''
}

export default {
  permissions: JSON.parse(localStorage.getItem('permissions') || '[]'), //权限列表
  btnPermissions: JSON.parse(localStorage.getItem('btnPermissions') || '[]'), //权限列表
  lang: 'zh',
  userInfo: JSON.parse(localStorage.getItem('userInfo') || JSON.stringify(noUser))
} as IState