import { IState } from '../types';
import { TAction } from './actions';
import initialState from './state';
import * as types from './actionTypes'


function reducer (state = initialState, action: TAction): IState {
  switch(action.type) {
    case types.SET_USERINFO: 
      localStorage.setItem('userInfo', JSON.stringify(action.payload));
      return {
        ...state,
        userInfo: action.payload
      }
    case types.SET_PERMISSIONS:
      localStorage.setItem('permissions', JSON.stringify(action.payload));
      return {
        ...state,
        permissions: action.payload
      }
    case types.SET_BTN_PERMISSIONS:
      localStorage.setItem('btnPermissions', JSON.stringify(action.payload));
      return {
        ...state,
        btnPermissions: action.payload
      }
    default:
      return state;
  }
}

export default reducer;