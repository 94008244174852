import React, { Suspense, useEffect, useState} from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { getUserPermissions, IPermissionData } from './api/userManager';
import { useAppDispatch, useAppSelector, generatePermissions } from './hooks';
import { setBtnPermissions, setPermissions } from './store/actions';
import NotFound from './views/errorPage/404';
import './assets/fonts/font.scss'
import KeepAlive from 'react-activation';

function App() {

  const permissions = generatePermissions(useAppSelector(state => state.reducer.permissions));
  
  const [fmenus, setFmenus] = useState<IPermissionData[]>(permissions);//菜单
  const dispatch = useAppDispatch();
  // console.log(fmenus, 'fmenus===')

  useEffect(() => {
    getUserPermissions()
      .then(res => {
        if(res.code === 200) {
          dispatch(setPermissions(res.data));
          dispatch(setBtnPermissions(res.data.filter(item => item.type === 1)))
          setFmenus(generatePermissions(res.data));
        }
      })
  }, [])

  return (
    <AppMain menus={fmenus}></AppMain>
  )
}

function AppMain(props: any) {
  const { menus } = props;
  return (
    <Suspense fallback={<h2>loading...</h2>}>
      <div className="App" style={{ padding: '20px', height: 'calc(100vh - 40px)' }}>
        <Routes>
          {renderRoute(menus)}
          {/* {renderRedirect(menus)} */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Suspense>
  );
}

//component加载方式：element={<item.component />}
const renderRoute = (menus: any) => {
  return menus.map((item: any) => {
    if (item.children) {
      return (
        <Route path={item.router_path} key={item.id}>
          {renderRoute(item.children)}
        </Route>
      );
    } else {
      return (
        <Route
          path={item.router_path}
          key={item.id}
          element={
            <KeepAlive id={item.router_path} when={item.cache}>
              <item.component />
            </KeepAlive>
          }
        ></Route>
      );
    }
  })
  
};

const renderRedirect = (menus: any) => {
  return <Route path="/" element={<Navigate to={menus[0].router_path} />}></Route>;
};


export default App;
