import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { IResponseType } from '../types';
import { message as AntMessage } from 'antd';
import store from '../store';
import { useAppDispatch } from '../hooks';
import { setUserInfo } from '../store/actions';

// 错误处理
const errorHandler = function(err: AxiosError) {
  switch (err.response?.status) {
    case 401:
      // console.log(window.location);
      AntMessage.warning('登录失效，请重新登录', 1, () => {
        localStorage.setItem('userInfo', JSON.stringify({userID: '', userName: ''}));
        window.location.reload();
      });
      break;
    default:
      AntMessage.warning(err.message, 1);
      break;
  }
  
}
axios.defaults.baseURL = process.env.NODE_ENV === 'development'
                       ? 'http://localhost:3000/api'
                       : process.env.REACT_APP_DOMAIN + '/api';

// console.log('react_app_domain', process.env.REACT_APP_DOMAIN);

axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  return config;
})

axios.interceptors.response.use((res: AxiosResponse) => {
  const { code, message } = res.data;
  if( code !== 200 && code !== 0 ) {
    if(res.config.responseType === 'blob')  return Promise.resolve(res);
    AntMessage.warning(message, 1);
    return Promise.reject(res.data);
  }
  return res;
}, (err: AxiosError) => {
  errorHandler(err);
  
  return Promise.reject(err);
})

const csrf = () => axios.get('/csrf-cookie');

const request = async <T>(config: AxiosRequestConfig): Promise<IResponseType<T>> => {
  await csrf();
  const res = await axios.request<IResponseType<T>>(config);
  return res.data;
}
export default request;