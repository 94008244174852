import React from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
// import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { useAppSelector, generatePermissions, useCurrentRoute } from '../hooks';
import { IPermissionData } from '../api/userManager';


function generateMenu(arr: any) {
  return arr.map((item: IPermissionData) => {
    return getItem(
      (item.router_path && item.parent_id) || item.router_path === '/home' ? <NavLink to={item.router_path} >{item.title}</NavLink> : item.title,
      item.id,
      null,
      item.children ? generateMenu(item.children) : null
    )
  })
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}


const SystemMenu: React.FC<{routerPath: string}> = ({routerPath}) => {

  const permissions = generatePermissions(useAppSelector(state => state.reducer.permissions), true);
  const currentRoute = useCurrentRoute(routerPath);
  
  const items: MenuProps['items'] = generateMenu(permissions);
  
  return (
    <div style={{background: '#fff'}}>
      <Menu
        // onClick={onClick}
        style={{ width: 256, boxShadow: 'rgb(9 9 9 / 33%) 0px 2px 4px 0px' }}
        defaultSelectedKeys={[currentRoute.key]}
        defaultOpenKeys={currentRoute.keyPath}
        mode="inline"
        items={items}
      />
    </div>
  );
};


export default SystemMenu;