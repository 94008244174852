import request from '../utils/request';
import {IPermissionData, IRoleData} from "./userManager";

export interface ILoginData {
  account: string;
  password: string;
  remember?: boolean;
}

export interface ILoginResponse {
  userID: string; //用户编号
  userName: string,//用户昵称
  roles: IRoleData[]; //所有的角色
  permissions: IPermissionData[]; //所有的权限
}

const login = (data: ILoginData) => {
  return request<ILoginResponse>({
    url: '/login',
    method: 'post',
    data
  });
}

const logout = () => {
  return request({
    url: '/logout',
    method: 'post'
  })
}

export {
  login,
  logout
}