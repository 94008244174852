import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Outlet, useLocation, useResolvedPath } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import Login from './views/login/login';
import Register from './views/login/register';
import { useNavigate } from 'react-router-dom';
import Logo from './assets/img/logo.png';
import SystemMenu from './components/SysMenu';
import { useAppDispatch, useAppSelector, useCurrentRoute } from './hooks';
import { logout } from './api/login';
import './assets/css/common.scss'
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';
import { setUserInfo } from './store/actions';
import LoadingProvider from './contexts/LoadingProvider';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import './i18n';
import { AliveScope } from 'react-activation';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <LoadingProvider>
      <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
        <Provider store={store}>
          <BrowserRouter >
            <AliveScope>
              <Routes>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/register" element={<Register />}></Route>
                <Route path='/' element={<Layout />}>
                  <Route path="/*" element={<App />}></Route>
                </Route>
              </Routes>
            </AliveScope>
          </BrowserRouter>
        </Provider>
      </StyleProvider>
    </LoadingProvider>
  </ConfigProvider>,
  document.getElementById('root') as HTMLElement
);


function Layout() {
  
  const dispatch = useAppDispatch();
  const [menuFlag, setMenuFlag] = useState(false);
  const userInfo = useAppSelector(state => state.reducer.userInfo);
  // const currentRoute = useAppSelector(state => state.reducer.currentRoute);
  const navigate = useNavigate(),
        location = useLocation();

  const currentRoute =      useCurrentRoute(location.pathname);
  
  useEffect(() => {
    if(menuFlag) {
      setMenuFlag(false);
    }
    if (!userInfo.userID) {
      // 记录退出登录前的页面
      localStorage.setItem("redirect", location.pathname + location.search);
      navigate('/login', { replace: true });
    } else {
      let redirect = localStorage.getItem('redirect');
      if(redirect) {
        navigate(redirect);
        localStorage.removeItem('redirect');
      }
      if (location.pathname === '/') {
        navigate('/home');
      }
    }
  }, [userInfo, location.pathname, navigate])

  //监听路由变化
  // useEffect(() => {
  //   setMenuFlag(false)
  // }, [location.pathname])

  //退出登陆
  function handleLogout() {
    logout().then(res => {
      dispatch(
        setUserInfo({
          userID: '',
          userName: ''
        })
      );
    });
  }

  return (
    <section onClick={() => setMenuFlag(false)}>
      <header style={{ background: '#edebeb', boxShadow: '0px 2px 4px 0px rgba(9,9,9,0.33)' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px' }}>
          <div style={{ flex: '0 0 150px', paddingLeft: '20px', display: 'flex', alignItems: 'center' }}>
            <img src={Logo} style={{ height: '40px' }} onClick={(e) => {
              setMenuFlag(!menuFlag);
              e.stopPropagation();
            }} alt="" />
            {
              menuFlag
                ? (
                  <div style={{ position: 'absolute', left: 0, top: '43px', zIndex: 999, maxHeight: '100vh', overflow: 'auto', padding: 10, background: '#fff'}}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <SystemMenu routerPath={location.pathname} />
                  </div>
                )
                : null
            }
          </div>
          <div style={{ textAlign: 'center', fontFamily: '思源黑体', fontWeight: 'bold', fontSize: '12px' }}>{currentRoute.title}</div>
          <div style={{ display: 'flex', flex: '0 0 150px', paddingRight: '10px', flexDirection: 'row-reverse', alignItems: 'flex-end', height: '40px', lineHeight: '40px', fontWeight: '600', color: 'rega(0,0,0,0.88)', fontSize: '14px' }}>
            <div style={{ flex: '0 0 50px', textAlign: 'center', cursor: 'pointer' }} onClick={handleLogout}>登出</div>
            <div style={{ flex: '0 0 50px', textAlign: 'center', cursor: 'pointer' }} onClick={()=>{navigate('/setting/index')}}>设置</div>
          </div>
        </div>
      </header>
      <Outlet />
      {/* <div>footer</div> */}
    </section>
  )
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
