const SET_USERINFO: string        = 'SET_USERINFO';      
//设置权限列表
const SET_PERMISSIONS: string     = 'SET_PERMISSIONS';
//设置按钮权限列表
const SET_BTN_PERMISSIONS: string     = 'SET_BTN_PERMISSIONS';

export {
  SET_USERINFO,
  SET_PERMISSIONS,
  SET_BTN_PERMISSIONS
}