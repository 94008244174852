import React, { FC, useEffect, useState, startTransition } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
// import 'antd/dist/reset.css';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
import leftLogo from '../../assets/img/leftLogo.png';
import { ILoginData, login } from '../../api/login';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Dispatch } from 'redux';
import { IUserInfo } from '../../types';
import { setPermissions, setUserInfo } from '../../store/actions';
import { IPermissionData } from '../../api/userManager';
import { useTranslation } from 'react-i18next';

const Login: FC = () => {

  const { t, i18n } = useTranslation(undefined, { useSuspense: false });
  const navigate = useNavigate();
  const dispatch: Dispatch = useAppDispatch();
  const userInfo = useAppSelector(state => state.reducer.userInfo);
  const [form] = Form.useForm();

  const setLoginStatus = (userInfo: IUserInfo) => {
    dispatch(setUserInfo(userInfo))
  }
  
  const setUserPermissions = (permissions: IPermissionData[]) => {
    dispatch(setPermissions(permissions));
  }

  useEffect(() => {
    var accountInfo = localStorage.getItem("accountInfo");
    // console.log(accountInfo)
    if(accountInfo) { //记住账号密码
      form.setFieldsValue(JSON.parse(accountInfo));
    }
  }, [])

  useEffect(() => {
    if(userInfo.userID) {
      navigate('/home');
    }
  }, [userInfo, navigate])

  const onFinish = async (values: ILoginData) => {
    // console.log('Success:', values);
    let res = await login({
      account: values.account,
      password: values.password
    }).catch(function(e){
      console.log(e);
    });
    if(res) {
      if(res.data.userID) {
        if(values.remember) {
          localStorage.setItem('accountInfo', JSON.stringify({
            ...values
          }));
        }else {
          localStorage.removeItem('accountInfo');
        }
        const { userID, userName, permissions } = res.data;
        setLoginStatus({
          userID,
          userName
        });
        const element = document.documentElement; // 获取要全屏的元素，这里是整个文档

        if (element.requestFullscreen) {
          element.requestFullscreen(); // 要求进入全屏模式
        }
      }
      navigate('/home', {replace: true});
    }
  };

  const changeLng = () => {
    let lng = i18n.language === 'zh-CN' ? 'en' : 'zh-CN';
    i18n.changeLanguage(lng);
    console.log(lng)
  }
 
  return (
    <div style={{width: '100vw', height: '100vh', padding: '10px 20px', background: '#f8f8f8'}}>
      <header style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '1px solid #000',paddingBottom:'3px'}}>
        <div><img src={leftLogo} style={{width: '180px'}} alt="" /></div>
        <div style={{cursor: 'pointer'}} onClick={changeLng}>中/Eng</div>
      </header>
      <div style={{width: '280px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        <div style={{textAlign: 'center'}}>
          <img src={Logo} alt="" style={{width: '80px', marginBottom: '10px'}} />
        </div>
        <Form
          name="basic"
          initialValues={{}}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelCol={{flex: i18n.language === 'en' ? '0 0 80px' : '0 0 auto' }}
        >
          <Form.Item
            label={t('login_account')}
            labelAlign='left'
            name="account"
            rules={[{ message: '请输入账号!' }]}
          >
            <Input style={{width: 230}} />
          </Form.Item>

          <Form.Item
            label={t('login_password')}
            labelAlign='left'
            name="password"
            rules={[{ message: '请输入密码' }]}
          >
            <Input.Password style={{width: 230}} />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>记住账号密码</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" className="grayBtn" style={{backgroundColor:'#DCDCDC',color:'#000', display: 'block', margin: '0 auto'}}>
              登陆
            </Button>
          </Form.Item>
        </Form>
      </div>
      {/* <footer style={{position: 'absolute', left: 0, bottom: '20px', width: '100%', textAlign: 'center',letterSpacing:'1px',lineHeight:'25px'}}>
        <div>没有围墙的学校，没有边界的教育</div>
        <div>School with no walls, Education without boundaries</div>
      </footer> */}
    </div>
  )
}

export default Login;
