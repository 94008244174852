import { AnyAction } from 'redux';
import { IPermissionData } from '../api/userManager';
import { IUserInfo, RouteData } from '../types';
import * as types from './actionTypes'

interface ISetUserInfo {
  type: string;
  payload: IUserInfo;
}

interface ISetCurrentRoute {
  type: string;
  payload: RouteData;
}

interface ISetPermissions {
  type: string;
  payload: IPermissionData[];
}

export type TAction = ISetUserInfo | ISetCurrentRoute | AnyAction; //action类型

function setUserInfo(userInfo: IUserInfo): ISetUserInfo {
  return {
    type: types.SET_USERINFO,
    payload: userInfo
  }
}

function setPermissions(permissions: IPermissionData[]): ISetPermissions {
  return {
    type: types.SET_PERMISSIONS,
    payload: permissions
  }
}

function setBtnPermissions(permissions: IPermissionData[]): ISetPermissions {
  return {
    type: types.SET_BTN_PERMISSIONS,
    payload: permissions
  }
}
export {
  setUserInfo,
  setPermissions,
  setBtnPermissions
}