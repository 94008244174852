import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IPermissionData } from "../api/userManager";
import { RootState, AppDispatch } from "../store";
import _ from 'lodash'
import React from "react";

const componentCache: any = {

};
console.log(componentCache)
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

//处理登录请求返回的权限列表
export const generatePermissions: (permissions: IPermissionData[], filter?: boolean) => IPermissionData[] = (permissions, filter) => {
  let result: IPermissionData[] = [];
  if(filter) {
    permissions = permissions.filter(item => item.hidden === 0);//过滤掉不需要显示的菜单
  }
  let deep = _.cloneDeep(permissions);
  deep.forEach((item) => {
    if(item.parent_id === 0) {
      result.push(item);
      if( item.router_path !== '/home' ) {
        
        item.children = deep.filter(child => {
          return child.parent_id === item.id
        });

        item.children.forEach(child => {
          if(child.router_path.indexOf(item.router_path) == -1) {
            child.router_path = item.router_path + child.router_path;
          }
          if(!componentCache[child.router_path]) {
            componentCache[child.router_path] = React.lazy(() => import('../views' + child.router_path + '.tsx'));
          }
          child.component = componentCache[child.router_path];
        })
      }else {
        item.component = React.lazy(() => import('../views' + item.router_path + '.tsx'));
      }
    }
  })
  return result;
}

// 返回当前的route信息
export const useCurrentRoute = (routerPath: string) => {
  const permissions = useAppSelector(state => state.reducer.permissions),
        route = permissions.filter(item => item.router_path === routerPath)[0];
        
  if(route) {
    return {
      key: route.id.toString(),
      keyPath: [ route.parent_id.toString() ],
      title: route.title,
      routerPath: route.router_path
    }
  }
  return {
    key: '',
    keyPath: [],
    title: '当前路由不存在',
    routerPath: ''
  };
}