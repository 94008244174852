import React, { FC } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
// import 'antd/dist/reset.css';

const Register: FC = () => {
  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <div>
      注册
    </div>
  )
}

export default Register;
