import React, { Key } from "react";
import request from "../utils/request";


// 新版权限
export function getNewPermissionsByUserID(userID: string) {
  return request<any>({
    url: '/permissions-config/permission',
    method: 'get',
    params: { userID }
  })
}
// 新版权限 - 更新用户权限
export function editNewPermissionsByUserID(data: any) {
  return request<any>({
    url: '/permissions-config/permission-upd',
    method: 'post',
    data
  })
}

// 编辑用户角色
export function editRoleConfig(data: any) {
  return request<any>({
    url: '/permissions-config/role-upd',
    method: 'post',
    data
  })
}

// 获取用户权限列表
export function getUserPermissions() {
  return request<IPermissionData[]>({
    url: '/users/permission',
    method: 'get'
  })
}

//用户管理
export interface IUserData {
  userID: string; //用户编号
  userName: string; //用户名称
  roleNames: string; //用户角色
  roles: IRoleData[]; //用户角色
  level: string; //系统功能级别
  email: string; //用户邮箱
  status: string; //用户状态
  language: string; //用户语言
  created_at: string; //创建时间
  lastLoginTime: string; //最后登录时间
  updated_at: string; //编辑时间
}


export interface IQueryUserData {
  list: IUserData[],
  roles: IRoleData[]
}

export interface IUserDataRequest {
  userID: string; //用户编号
  userName: string; //用户名称
  roles?: IRoleData[]; //用户角色
  level: string; //系统功能级别
  email: string; //用户邮箱
  status: string; //用户状态
  language: string; //用户语言
}

//获取用户列表信息
export function getUserList (name?: string) {
  return request<IQueryUserData>({
    url: '/users',
    method: 'get',
    params: { name }
  })
}

//添加用户
export function addUser(data: IUserDataRequest) {
  return request({
    url: '/users/create',
    method: 'post',
    data
  })
}

//编辑用户信息
export function editUser(data: IUserDataRequest) {
  return request({
    url: '/users/'+ data.userID +'/update',
    method: 'patch',
    data
  })
}

export interface IRoleData {
  id: number; //角色编号
  name: string; //角色名称
  created_at?: string; //创建时间
  updated_at?: string; //编辑时间
}

export interface showRolePermissionData {
  permissions:IPermissionData[];
  rolePermissions: Key[];
}

export interface IRolePermissionData {
  id: number; //角色编号
  name: string; //角色名称
  rolePermissions: IPermissionData[]; //角色权限
}


export interface IRoleDataRequest {
  id: number;   //角色编号
  name: string; //角色名称
}

export interface IRolePermissionDataRequest {
  rolePermissions: IRolePermissionData[];
}

//获取角色列表信息
export function getRoleList (name?: string) {
  return request<IRoleData[]>({
    url: '/roles',
    method: 'get',
    params: { name }
  })
}

//通过id获取角色信息
export function getRoleInfo (id: number) {
  return request<showRolePermissionData>({
    url: '/roles/'+ id +'/show',
    method: 'get'
  })
}

//添加角色
export function addRole(data: IRoleDataRequest) {
  return request({
    url: '/roles/create',
    method: 'post',
    data
  })
}

//编辑角色信息
export function editRole(data: IRoleDataRequest) {
  return request({
    url: '/roles/'+ data.id +'/update',
    method: 'patch',
    data
  })
}

//删除角色信息
export function delRole(id: number) {
  return request({
    url: '/roles/'+ id +'/delete',
    method: 'delete',
    data: { id }
  })
}

//角色权限设置
export function addRowPermissions(data: {id: number, ids: Key[]}) {
  return request({
    url: '/roles/' + data.id + '/permissions',
    method: 'post',
    data: {
      ids: data.ids
    }
  })
}

//权限资源
export interface IPermissionData {
  id: number; //权限编号
  key: number; //权限编号
  parent_id: number; //上级权限编号
  title: string; //权限中文标题
  name: string; //权限资源名称
  path: string; //后端路由
  router_path: string, //前端路由地址（和目录映射关系一致）
  guard_name?: string; //权限认证类型
  sort: number; //排序
  hidden: number; //是否显示在菜单栏
  type: number; //是否按钮权限  1：按钮  其他：不是
  children?: IPermissionData[]; //下级权限
  created_at?: string; //创建时间
  updated_at?: string; //编辑时间
  component?: React.LazyExoticComponent<React.ComponentType<any>>
}

//权限请求
export interface IPermissionDataRequest {
  permissions: IPermissionData[];
}

//获取权限列表信息
export function getPermissionList (name?: string) {
  return request<IPermissionData[]>({
    url: '/permissions',
    method: 'get',
    params: { name }
  })
}

//添加权限
export function addPermission(data: IPermissionData) {
  return request({
    url: '/permissions/create',
    method: 'post',
    data
  })
}

//通过id获取权限信息
export function getPermissionInfo (id: number) {
  return request<IPermissionData>({
    url: '/permissions/'+ id +'/show',
    method: 'get'
  })
}

//编辑权限信息
export function editPermission(data: IPermissionData) {
  return request({
    url: '/permissions/'+ data.id +'/update',
    method: 'patch',
    data
  })
}

//删除权限信息
export function delPermission(id: number) {
  return request({
    url: '/permissions/'+ id +'/delete',
    method: 'delete',
    data: { id }
  })
}