import React, { useState } from 'react';
import { Spin } from 'antd';
import LoadingContext from './LoadingContext';

const LoadingProvider: React.FC<{children: any}> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 9998,
          }}
        >
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: 9999
          }}>
            <Spin size="large" tip="加载中..." />
          </div>
        </div>
      )}
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;